<template>
  <b-modal
    id="modal-preview-income-expense-template"
    ref="refModal"
    title="Mẫu in phiếu thu"
    ok-title="Đóng"
    ok-only
    size="lg"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable
    @show="fetchPdfPreviewUrl"
    @hidden="resetModal"
  >
    <div v-if="isLoading" class="d-flex justify-content-center mb-1">
      <b-spinner class="text-center" variant="secondary" />
    </div>
    <div v-else>
      <b-button class="mb-1" variant="primary" @click="downloadTemplate">
        Tải mẫu
      </b-button>
      <iframe
        v-if="pdfUrl && !isLoading"
        :src="pdfUrl"
        style="width: 100%; height: 80vh; border: none"
      />
    </div>
  </b-modal>
</template>

<script>
import { BModal, BSpinner, BButton } from "bootstrap-vue";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import useTemplatePreviewModal from "./useIncomeExpenseTemplatePreviewModal";

export default {
  components: {
    BModal,
    BSpinner,
    BButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const {
      refModal,
      itemLocal,
      fetchPdfPreviewUrl,
      pdfUrl,
      isLoading,
      resetItemLocal,
      resetModal,
      downloadTemplate,
    } = useTemplatePreviewModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isLoading,
      resetItemLocal,
      resetModal,
      fetchPdfPreviewUrl,
      pdfUrl,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      downloadTemplate,
    };
  },
};
</script>
./useTemplatePreviewModal
