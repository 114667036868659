<template>
  <b-modal
    id="modal-income-expense-template"
    ref="refModal"
    :title="t('Mẫu in phiếu thu')"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col>
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group :label="t('Tên')" class="mt-2">
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <!-- Description -->
            <validation-provider
              #default="validationContext"
              name="Mô tả"
              rules=""
            >
              <b-form-group :label="t('Mô tả')">
                <b-form-textarea
                  v-model="itemLocal.description"
                  :state="getValidationState(validationContext)"
                  rows="3"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <file-input v-model="itemLocal.file" accept=".docx" />

        <b-row>
          <b-col cols="12">
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.default"
                name="check-button"
                switch
                inline
              >
                {{ t("Mặc định") }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BFormTextarea,
  BCol,
  BRow,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import FileInput from "@/views/components/FileInput.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useTemplateModal from "./useIncomeExpenseTemplateModal";

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BCol,
    BRow,
    BFormCheckbox,
    FileInput,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();
    const { t } = useI18nUtils();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
    } = useTemplateModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
};
</script>
./useTemplateModal
