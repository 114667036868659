import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTemplates(ctx, params) {
      return useJwt.getIncomeExpenseTemplates(params).then(response => response);
    },
    createTemplate(ctx, data) {
      return useJwt.createIncomeExpenseTemplate(data).then(response => response);
    },
    updateTemplate(ctx, data) {
      return useJwt.updateIncomeExpenseTemplate(data).then(response => response);
    },
    previewTemplate(ctx, id) {
      return useJwt.previewIncomeExpenseTemplate(id).then(response => response);
    },
    deleteTemplates(ctx, data) {
      return useJwt.deleteIncomeExpenseTemplates(data).then(response => response);
    },
  },
};
